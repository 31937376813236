import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import { QueryClient, QueryClientProvider } from 'react-query';
import { getTrack } from './api/tracking';
import { getEmailSignature, getSignedUser } from './api/user';
import './App.scss';
import WizardStructure from './components/common/WelcomeScreen/WizardScreen';
import WizardModal from './components/refactored/Modal/Wizard/Wizard';
import LoginRoute from './components/Routes/LoginRoute';
import PrivateRoute from './components/Routes/PrivateRoute';
import SiteUnderMaintainence from './components/SiteUnderMaintainence';
import { CRISP_WEBSITE_ID } from './constant';
import ForgetPassword from './pages/auth/ForgetPassword';
import LoginForm from './pages/auth/LoginForm';
import PasswordConfirmation from './pages/auth/PasswordConfirmation';
import ResetPassword from './pages/auth/ResetPassword';
import VerificationScreen from './pages/auth/VerificationScreen';
import CustomerRedirectDashboard from './pages/dashboard/CustomerRedirectDashboard';
import Dashboard from './pages/dashboard/Dashboard';
import Demo from './pages/demo/Demo';
import { getCustomerApi } from './redux/actions/notificationAction';
import { setIsMobile, setTrackingCode, setTrackingLoading } from './redux/actions/trackingAction';
import { setCurrentLoginUser } from './redux/actions/userMemberAction';
import { TrackingReduxState } from './redux/reducers/trackingReducer';
import { RootState } from './redux/store';

/***********************
 * temporary solution to remove login user key from local storage for every user
 */
localStorage.removeItem('login user');
/***********************/
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false
    }
  }
});

function copyAttrs(src, target) {
  for (const attr of src.attributes) {
    target.setAttribute(attr.name, attr.value);
  }
}

function App(props: any) {
  const dispatch = useDispatch();
  const trackReducer = useSelector<RootState, TrackingReduxState>((state) => state.trackReducer);

  const [initialDataLoaded, setInitialDataLoaded] = useState(false);

  const handleScreenSize = () => {
    if (trackReducer?.isMobile !== window.innerWidth < 992)
      dispatch(setIsMobile(window.innerWidth < 992));
  };

  useEffect(() => {
    handleScreenSize();
    window.addEventListener('resize', handleScreenSize);
    (window as any).$crisp = [];
    (window as any).CRISP_WEBSITE_ID = CRISP_WEBSITE_ID;

    (async function () {
      const d = document;
      const s = d.createElement('script');

      s.src = 'https://client.crisp.chat/l.js';
      s.async = true;
      d.getElementsByTagName('head')[0].appendChild(s);

      try {
        const userInfo = await getSignedUser();
        const { signature } = await getEmailSignature();
        setTimeout(() => {
          setInitialDataLoaded(true);
        }, 0);

        if (!userInfo) return;

        dispatch(setCurrentLoginUser(userInfo));
        dispatch(getCustomerApi(userInfo));

        const crisp = (window as any).$crisp;
        console.log('crisp', crisp);
        if (crisp) {
          crisp.push(['set', 'user:nickname', [`${userInfo.firstName} ${userInfo.lastName}`]]);
          crisp.push(['set', 'user:email', [userInfo.email, signature]]);
          if (userInfo.avatar) {
            crisp.push(['set', 'user:avatar', [userInfo.avatar]]);
          }
        }
      } catch (e) {
        setInitialDataLoaded(true);
      }
    })();

    return () => {
      window.removeEventListener('resize', handleScreenSize);
    };
  }, []);
  useEffect(() => {
    dispatch(setTrackingLoading(true));
    getTrack()
      .then((res) => {
        const { data } = res;
        const trackCode = data.length ? data[0] : {};
        dispatch(setTrackingCode(trackCode));
        dispatch(setTrackingLoading(false));
        appendTracking(data);
      })
      .catch((error) => {
        dispatch(setTrackingLoading(false));
      });
  }, [dispatch]);

  const appendTracking = (data: any) => {
    const trackerContent = data.length && decodeURIComponent(data[0].code);
    const div = document.createElement('div');
    div.innerHTML = trackerContent;

    const nodechildList = div.childNodes;

    nodechildList.forEach((item) => {
      if (item && (item as any).tagName === 'SCRIPT') {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.textContent = item.textContent;
        copyAttrs(item, script);
        document.head.appendChild(script);
      }
    });
  };

  if (!initialDataLoaded) return null;
  return (
    <div>
      <QueryClientProvider client={queryClient}>
        <Router>
          <Switch>
            <Route exact path="/" component={Demo} />
            <PrivateRoute
              path="/dashboard/customer/:customerId"
              component={CustomerRedirectDashboard}
            />
            <PrivateRoute path="/dashboard" component={Dashboard} />
            <LoginRoute path="/login" component={LoginForm} />
            <Route path="/site-maintainence" component={SiteUnderMaintainence} />
            <Route path="/forget-password" component={ForgetPassword} />
            <Route path="/getting-started" component={WizardModal} />
            <Route path="/next-screen" component={WizardStructure} />
            <Route path="/verify" component={VerificationScreen} />
            <Route path="/reset_password/:token?" component={ResetPassword} />
            <Route path="/password-confirmed" component={PasswordConfirmation} />
          </Switch>
        </Router>
      </QueryClientProvider>
    </div>
  );
}

export default App;
